import * as React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import {listLink} from '../components/layout.module.css'


const AlphabeticalIndex = ({data}) => {
  return (
    <Layout pageTitle="Alphabetical Index">
    <div>
      {
        data.allMdx.nodes.sort(
          function(a, b) {
            var nameA = a.frontmatter.title.toUpperCase(); // ignore upper and lowercase
            var nameB = b.frontmatter.title.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          }
        )
        .map(node => (
          <p key={node.frontmatter.title} className={listLink}>
            <Link to={"/" + node.frontmatter.slug}>
            {node.frontmatter.title}
            </Link>
          </p>
        ))
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
query {
  allMdx(
    sort: {fields: frontmatter___title, order: ASC}
  ) {
    nodes {
      frontmatter {
        title
        slug
      }
      id
    }
  }
}
`

export default AlphabeticalIndex